export const splitValueFromUnit = (cssValue: string) => {
  const ValueRegExp = /[.\/\\\d]+/;
  const result = {
    original: cssValue,
    value: 0,
    unit: '',
    isSuccessful: false,
  };

  const cleanWord = cssValue.replace(/\s/, '');
  const numberMatch = cleanWord.match(ValueRegExp);

  if (numberMatch) {
    const numberValue = Number(numberMatch[0]);

    if (!isNaN(numberValue)) {
      result.value = numberValue;
      result.isSuccessful = true;
      result.unit = cleanWord.replace(numberMatch[0], '');
    }
  }

  return result;
}

export const hexToRgb = (hex: string) => {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result ? `${parseInt(result[1], 16)},${result[2], 16},${result[3], 16}` : '0,0,0';
}