import React from 'react'
import { FieldArray, useFormikContext } from 'formik';
import { ProductChoices, BidSupportFormValues } from '../BidSupportForm/BidSupportForm.schema';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { RepeaterFieldProps } from './RepeaterField.types';
import { Field, SelectInput, TextInput, CheckboxInput } from '..';
import { FieldMeta } from '../../data/types';

export const ProductRepeaterField: React.FC<RepeaterFieldProps> = ({
  name
}) => {
  const { values } = useFormikContext<BidSupportFormValues>();
  const repeaterData = values[name] as ProductChoices;

  const defaultItem = {
    productCategory: '',
    product: '',
    quantity: '',
    demoProductRequired: false
  };

  const categories = ["Select...", "Inkjet", "Laser", "Scan", "Imaging", "Other"];

  let categoryValues: FieldMeta[] = [];

for (var i = 0; i < categories.length; i++)
{
  categoryValues.push({
    "identifier": categories[i],
    "label": categories[i], 
    "value": (i > 0) ? categories[i] : ""
  })
}

  return (
    <FieldArray
      name={name as string}
      validateOnChange={false}
    >
      {({remove, push}) => (
        <div className='field-repeater'>
          <table>
            <thead>
              <tr>
                <th>Product Category</th>
                <th>Product</th>
                <th>Quantity</th>
                <th>Demo Required</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {repeaterData.map((obj, inx) => (
                <tr key={inx}>
                  <td>
                    <Field
                      name={`${name}.${inx}.productCategory`}
                      component={SelectInput}
                      values={categoryValues}
                    />
                  </td>
                  <td>
                    <Field
                      name={`${name}.${inx}.product`}
                      component={TextInput}
                    />
                  </td>
                  <td>
                    <Field
                      name={`${name}.${inx}.quantity`}
                      component={TextInput}
                    />
                  </td>
                  <td>
                  <Field
                    name={`${name}.${inx}.demoProductRequired`}
                    label=''
                    component={CheckboxInput}
                    values={[ { "identifier": "yes", "label": " ", "value":"Yes" } ]}
                  />     
                  </td>
                  <td>
                    {repeaterData.length > 1 ? (
                      <button className='no-appearence' type='button' onClick={() => remove(inx)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={4}>
                  <button className='no-appearence color-highlight m-top-05' type='button' onClick={() => push(defaultItem)}>
                    + Add a row
                  </button>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      )}
    </FieldArray>
  )
}