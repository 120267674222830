import React from "react";
import Lottie from 'react-lottie';
import * as animationData from '../../animations/not_found.json'
import { EmptyAnimatedContainer } from "./EmptyAnimated.styles";
import { EmptyAnimatedProps } from "./EmptyAnimated.types";

export const EmptyAnimated: React.FC<EmptyAnimatedProps> = ({
  text
}) => {

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };  

  return (
    <EmptyAnimatedContainer>
      <Lottie options={defaultOptions}
              height={400}
              width={400} />
              <div className="notfound-text">{text}</div>
    </EmptyAnimatedContainer>

  )
}