import styled from "styled-components";
import SimpleBar from 'simplebar-react';
import { ScrollviewProps } from "./Scrollview.types";
import { configService } from '../../services';

export const ScrollWindow = styled(SimpleBar)<ScrollviewProps>`
  max-height: ${props => props.maxsize}px;
  overflow-x: hidden;
  
  .simplebar-track.simplebar-vertical {
    background: ${configService.current().colours.GREY_LIGHT};
    width: 10px;
    border-radius: 10px;
  }

  .simplebar-scrollbar:before {
    background: ${configService.current().colours.BLACK};
    opacity: 1;
  }
`;

export const ScrollBarSpacer = styled.div`
  
`