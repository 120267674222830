import styled from "styled-components";

export const MyDealsTable = styled.table`

& svg {
    margin-left:5px;
}

& .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;

    & img {margin: 5px;}
}

& .row-HP {
    background-color: #40DEFA;
}

& .row-HPE {
    background-color: #01A982;
}

& img {
    max-width: 50px;
}
    
`