import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useQuiz } from '../modules/Training';
import { configService } from '../services';
import { GridContainer } from '../components';
import { LayoutType } from '../services/config.service.types';

export const Episode: React.FC = () => {
  const { episodeId } = useParams();
  const { Quiz } = useQuiz(episodeId || '');
  const isLegacy = configService.current().layoutType === LayoutType.Legacy;

  return (
    <div>
      <Helmet>
        <title>{configService.current().applicationTitle} - Learn - Quiz</title>
      </Helmet>
      {(!isLegacy) ?
        <Quiz /> :
        <GridContainer className='m-x-auto p-top-1'>
          <Quiz />
        </GridContainer>
      }
    </div>
  )
}