import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Carousel, Cell, Row } from '../../../components';
import { CarouselSettingProps } from '../../../components/Carousel/Carousel.types';
import { useWindow } from '../../../hooks';
import { AssetCollectionProps } from '../Assets.types';
import { Asset } from './Asset.component';
import { AssetWrapper } from './Asset.styles';

export const AssetCollection: React.FC<AssetCollectionProps> = ({
  showTitle,
  GroupName,
  Assets,
  Subgroups
}) => {
  const { Grid } = useContext(ThemeContext);
  const { width } = useWindow();

  const settingsLG: CarouselSettingProps = {
    slidesToShow: 3,
    slidesToScroll: 3,
  }

  const settingsSM: CarouselSettingProps = {
    slidesToShow: 2,
    slidesToScroll: 2,
  }


  return (
    <section>
      {showTitle && (<h2 className="font-title text-center">{GroupName}</h2>)}
      <div className="w-100">
        {width > Grid.Breakpoints.LG ? (<>
          <Row>
            {Assets.map((a, inx) => (
              <Cell baseSize={3} key={inx}>
                <AssetWrapper>
                  <Asset {...a} />
                </AssetWrapper>
              </Cell>
            ))}
          </Row>
          {Subgroups.map((s, s_inx) => (<div key={"subgroup" + s_inx}>
            <h3 className="font-title">{s.GroupName}</h3>
            <Row>
              {s.Assets.map((a, inx) => (
                <Cell baseSize={3} key={inx}>
                  <AssetWrapper>
                    <Asset {...a} />
                  </AssetWrapper>
                </Cell>
              ))}
            </Row>
          </div>
          ))}
        </>
        ) : (
          <>
            <Carousel
              RenderComponent={Asset}
              CarouselItems={Assets}
              slidesToShow={4}
              slidesToScroll={4}
              settingsLG={settingsLG}
              settingsSM={settingsSM}
              showArrows
            />
            {Subgroups.map((s, s_inx) => (<div key={"subgroup" + s_inx}>
              <h3 className="font-title">{s.GroupName}</h3>
              <Carousel
                RenderComponent={Asset}
                CarouselItems={s.Assets}
                slidesToShow={4}
                slidesToScroll={4}
                settingsLG={settingsLG}
                settingsSM={settingsSM}
                showArrows
              />
            </div>
            ))}
          </>
        )}
      </div>
    </section>
  )
}