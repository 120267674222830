import styled from "styled-components";

export const AssetLink = styled.a`
  display: flex;
  text-decoration: none;
  color: ${props => props.theme.TextColour};
  height: 100%;
  cursor: pointer;
  border-width: ${props => props.theme.ListItem.BorderWidth};
  border-color: ${props => props.theme.ListItem.BorderColor};
  border-style: ${props => props.theme.ListItem.BorderStyle};
  border-radius: ${props => props.theme.ListItem.BorderRadius};
  
  & > svg {
    align-self: center;
    margin-right: 0px;
    margin-left: auto;
    padding-right: 5px;
  }

  & > div {
    margin: 0;
    height: 100%;
  }

  & .asset-details-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    & p {
      margin-bottom: 0px;
      margin-top: auto;
    }
  }
`
export const AssetWrapper = styled.div`
  padding-bottom: ${props => props.theme.Grid.GutterSize};
  height: 100%;
`