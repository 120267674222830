import { useEffect, useState } from "react";
import { getUser, getApplicationProfile } from "./Profile.services";
import { UserModel, ApplicationProfileModel } from "outbound-api-client/lib/models";

export const useProfile = () => {
  const [user, setUser] = useState<UserModel>();
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [hasProfileError, setHasProfileError] = useState(false);

  useEffect(() => {
    setIsProfileLoading(true);
    getUser()
      .then((data) => {
        setUser(data);
      })
      .catch((err) => {
        console.error(err);
        setHasProfileError(true);
      })
      .finally(()=> {
        setIsProfileLoading(false);
      });
  }, []);

  return {
    user,
    isProfileLoading,
    hasProfileError,
  }
}

export const useApplicationProfile = () => {
  const [applicationProfile, setApplicationProfile] = useState<ApplicationProfileModel>();
  const [isApplicationProfileLoading, setIsApplicationProfileLoading] = useState(false);
  const [hasApplicationProfileError, setHasApplicationProfileError] = useState(false);

  useEffect(() => {
    setIsApplicationProfileLoading(true);
    getApplicationProfile()
      .then((data) => {
        setApplicationProfile(data);
      })
      .catch((err) => {
        console.error(err);
        setHasApplicationProfileError(true);
      })
      .finally(()=> {
        setIsApplicationProfileLoading(false);
      });
  }, []);

  return {
    applicationProfile,
    isApplicationProfileLoading,
    hasApplicationProfileError,
  }
}