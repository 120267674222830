import { RewardModel } from "outbound-api-client/lib/models";
import { useEffect, useState } from "react";
import { RewardType } from "../../data/enums";
import { getRewards } from "./reward.services";

export const useRewards = (rewardType: RewardType) => {
  const [rewards, setRewards] = useState<RewardModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getRewards()
      .then((rewards) => {
        setRewards(rewards);
      })
      .catch((err) => {
        console.error(err);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, []);

  return {
    rewards,
    isLoading,
    hasError,
  }
}