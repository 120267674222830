import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useCampaignsDispatch } from '../../modules';
import { apiService, configService } from '../../services';
import { useAppDispatch } from '../../store';
import { LegacyHeaderBar, LegacyNav } from './LegacyHeader.styles';
import { doLogout } from '../../hooks';
import { Hamburger } from '../Hamburger';
import { useTheme } from 'styled-components';

export const LegacyHeader: React.FC = () => {

    const isLoggedIn = apiService.instance.isLoggedIn();
    const { campaignId } = useParams();
    const theme = useTheme();

    const dispatch = useAppDispatch();
    const { campaigns, hasContent } = useCampaignsDispatch(dispatch);
    const defaultCampaign = hasContent && campaigns.length === 1 ? campaigns[0] : null;

    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
      };

    return (
        <>
        <LegacyHeaderBar className="header fixed-top" id="legacy-header">
            <div className="wrapper">
                {(isLoggedIn) ? <>
                <div className='block mobile-menu-toggle'>
                    <Hamburger isOpen={isMenuOpen} onClick={toggleMenu} />
                </div>
                <div className='block'>
                    <a href="#" className="brand">
                        <img src={theme.Header.LockupImageUrl} alt='HPE | HPI | Intel | Poly | SCC' title='HPE | HPI | Intel | Poly | SCC'/>                        
                    </a> 
                </div>
                <div className='block desktop-menu'>
                    <LegacyNav>
                        {(defaultCampaign === null) ?
                            <Link to={`/`}>Home</Link> : <Link to={`/incentive/${defaultCampaign.identifier}`}>Home</Link>}
                        <Link to={`/incentive/${campaignId}/mydeals`}>My Deals</Link>
                        <Link to={`/incentive/${campaignId}/dealreg`}>Log Deal</Link>
                        {(configService.current().navigationProps.showSalesTools) ? 
                        <Link to={`/incentive/${campaignId}/salestools`}>Sales Tools</Link> : <></> }
                        {(configService.current().navigationProps.showQuiz) ? 
                        <Link to={`/incentive/${campaignId}/learn`}>Quiz</Link> : <></> }
                        {(configService.current().navigationProps.showLeaderboard) ? 
                        <Link to={`/incentive/${campaignId}/leaderboard`}>Leaderboard</Link> : <></> }
                        <Link to={`/incentive/${campaignId}/article/Prize_Page_EN_GB`}>The Prize</Link>
                        <Link to="" onClick={doLogout}>Log out</Link>
                    </LegacyNav>
                </div>
                </> : 
                <div style={{width: "100%", textAlign: "center"}}>
                    <span className="brand">
                        <img src={theme.Header.LockupImageUrl} alt='HPE | HPI | Intel | Poly | SCC' title='HPE | HPI | Intel | Poly | SCC'/>      
                    </span> 
                </div>}            
            </div>
            {(isMenuOpen) ? 
            <div className='block mobile-menu'>
                <div>{(defaultCampaign === null) ?
                        <Link to={`/`}>Home</Link> : <Link to={`/incentive/${defaultCampaign.identifier}`}>Home</Link>}</div>
                        <div><Link to={`/incentive/${campaignId}/mydeals`} onClick={toggleMenu}>My Deals</Link></div>
                        <div><Link to={`/incentive/${campaignId}/dealreg`} onClick={toggleMenu}>Log Deal</Link></div>

                        {(configService.current().navigationProps.showSalesTools) ? 
                        <div><Link to={`/incentive/${campaignId}/salestools`} onClick={toggleMenu}>Sales Tools</Link></div> : <></> }
                        {(configService.current().navigationProps.showQuiz) ? 
                            <div><Link to={`/incentive/${campaignId}/learn`} onClick={toggleMenu}>Quiz</Link></div> : <></> }
                        {(configService.current().navigationProps.showLeaderboard) ? 
                            <div><Link to={`/incentive/${campaignId}/leaderboard`} onClick={toggleMenu}>Leaderboard</Link></div> : <></> }
                        
                        <div><Link to={`/incentive/${campaignId}/article/Prize_Page_EN_GB`} onClick={toggleMenu}>The Prize</Link></div>
                        <div><Link to="" onClick={doLogout}>Log out</Link></div>
                </div> : <></>}
        </LegacyHeaderBar>
        
        </>
    );
}