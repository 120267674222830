import { SendGridEmailTemplate, EmailSendModel } from 'outbound-api-client/lib/models';
import { apiService } from "../../services";

export const getEmailTemplates = async (): Promise<Array<SendGridEmailTemplate>> => {
   return await apiService.instance.getEmailTemplates();
}

export const getExistingSends = async (): Promise<Array<EmailSendModel>> => {
   return await apiService.instance.getExistingEmailSends();
}

