import React from 'react';
import { CardContent, CardWrapper } from './Card.styles';
import { CardProps } from './Card.types';

export const Card: React.FC<CardProps> = ({
  headerImg,
  children,
  cssClasses,
  contentCSS,
  hideWrapper
}) => {
  return (
    (!hideWrapper) ? 
    <CardWrapper className={cssClasses}>
      {headerImg && (<img src={headerImg} alt='' />)}
      {children && (
        <CardContent className={contentCSS}>
          {children}
        </CardContent>
      )}
    </CardWrapper> : 
    <>{headerImg && (<img src={headerImg} alt='' />)}
    {children && (
      <CardContent className={contentCSS}>
        {children}
      </CardContent>)}</> 
  )
} 