import styled from "styled-components";
import { hexToRgb } from "../../style/helpers";
import { NavTileImageProps, NavStatusProps, TileBorderProps } from "./NavTile.types";

export const NavTileWrapper = styled.div<TileBorderProps>`
  background: ${props => props.backgroundColour};
  border-radius: ${props => props.theme.Base.BorderRadius};
  box-shadow: ${props => `${props.theme.Base.DropShadowPlacement} rgba(${hexToRgb(props.theme.Base.DropShadowColor)},${props.theme.Base.DropShadowAlpha});`}
  
  a {
    display: inline-block;
    width: 100%;
    text-decoration: ${props => props.theme.Nav.TextDecoration};
    color: ${props => props.theme.Nav.TextColour};
    transition: all ${props => props.theme.AnimationTimingFast} ease-in-out;
    
    &:hover{
      color: ${props => props.theme.Nav.HoverColour};
      text-decoration: ${props => props.theme.Nav.TextDecorationOnHover};
    }
  }
`
  
export const NavBorder = styled.div<NavStatusProps>`
  overflow: auto;
  border: 3px solid ${props => props.isActive ? props.theme.TextColour : props.backgroundColour};
  border-radius: ${props => props.theme.Base.BorderRadius};
  transition: all ${props => props.theme.AnimationTimingFast} ease-in-out;
`;

export const NavTileImage = styled.div<NavTileImageProps>`
  width: 100%;
  padding-bottom: ${props => props.showImage ? '60%' : '0'};
  background: url('${props => props.img}') center center no-repeat;
  background-size: cover;
  border-radius: ${props => props.theme.Base.BorderRadius};
  transition: padding ${props => props.theme.AnimationTimingFast} ease-in-out;
`

export const NavTileHeading = styled.p`
  text-align: center;
  text-decoration: ${props => props.theme.Nav.TextDecoration};
  color: ${props => props.theme.Nav.TextColour};
  transition: all ${props => props.theme.AnimationTimingFast} ease-in-out;
  margin: 0.6em 0 !important;

  &:hover{
    color: ${props => props.theme.Nav.HoverColour};
    text-decoration: ${props => props.theme.Nav.TextDecorationOnHover};
  }
`