import { useEffect, useState } from "react";
import { getScores } from "./Leaderboard.services";
import { UserScore } from "./Leaderboard.types";

export const useLeaderboard = (campaignIdentifier = '') => {
  const [scores, setScores] = useState<UserScore[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getScores(campaignIdentifier)
      .then((board) => {
        setScores(board);
      })
      .catch((err) => {
        console.error(err);
        setHasError(true);
      })
      .finally(()=> {
        setIsLoading(false);
      });
  }, []);

  const podium = [...scores.slice(0, 3)];

  return {
    scores,
    podium,
    isLoading,
    hasError,
  }
}