import { AssetGroup } from "./Assets.types";
import { AssetModel } from "outbound-api-client/lib/models";
import { apiService } from '../../services';

export const getAssets = async (campaignIdentifier: string | null, query: string | null = null): Promise<AssetModel[]> => {
  if (apiService.instance.isLoggedIn())
  {
    return apiService.instance.getAssets(campaignIdentifier, query);
  }
  else
  {
    return [];
  }
}

export const buildAssetGroups = (assets: AssetModel[], useCategory = true, title = '') => {
  const onlyUnique = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  }
  const groups: AssetGroup[] = [];
  const productFamilies = assets.map(a => a.productFamily);
  const uniqueFamilies = productFamilies.filter(onlyUnique);
  const sortedUniqueFamilies = uniqueFamilies.sort((a, b) => a.localeCompare(b));
  
  const groupNames = new Set(sortedUniqueFamilies);
  
  if (useCategory) {
    groupNames.forEach(name => {
      const filteredAssets = assets.filter(a => a.productFamily === name);
      const subGroups = filteredAssets.map(a => (a.productModel === null ? "":a.productModel));
      const uniqueSubGroups = subGroups.filter(onlyUnique);
      const sortedUniqueSubGroups = uniqueSubGroups.sort((a, b) => a.localeCompare(b));

      let subgroups: AssetGroup[] = [];

      sortedUniqueSubGroups.forEach(model => {
        subgroups.push({
          GroupName: model,
          Priority: 0,
          Assets: filteredAssets.filter(a => (a.productModel === null ? "":a.productModel) === model),
          Subgroups: []
        })
      });

      const group: AssetGroup = {
        GroupName: name,
        Priority: 0,
        Assets: [],
        Subgroups: subgroups
      };

      //sort assets in group

      groups.push(group);
    });
  } else {
    const group: AssetGroup = {
      GroupName: title,
      Priority: 0,
      Assets: assets,
      Subgroups: []
    };

    //sort assets in group

    groups.push(group);
  }

  //sort groups

  return groups;
}
