export enum FeedDisplayType {
  None = "NONE",
  Hero = "HERO",
  Primary = "PRIMARY",
  Secondary = "SECONDARY",
  Notification = "NOTIFICATION",
  Reward = "REWARD",
  TrainingEpisode = "TRAINING_EPISODE",
  Vide = "VIDEO",
};
