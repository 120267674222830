import styled from "styled-components";
import { fonts } from "../../style/fonts";
import { FontStyle } from "./Heading.types";

export const H1 = styled.h1<FontStyle>(props => (
  props.FontStyle ? fonts[props.FontStyle] : fonts.Title
));

export const H2 = styled.h2<FontStyle>(props => (
  props.FontStyle ? fonts[props.FontStyle] : fonts.SubTitle
));

export const H3 = styled.h3<FontStyle>(props => (
  props.FontStyle ? fonts[props.FontStyle] : fonts.Heading
));

export const H4 = styled.h4<FontStyle>(props => (
  props.FontStyle ? fonts[props.FontStyle] : fonts.SubHeading
));

export const H5 = styled.h5<FontStyle>(props => (
  props.FontStyle ? fonts[props.FontStyle] : fonts.Body
));