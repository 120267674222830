import styled from "styled-components";
import { configService } from '../../services';
import { hexToRgb, splitValueFromUnit } from "../../style/helpers";

export const SummaryWrapper = styled.div`
  border-radius: ${props => props.theme.Base.BorderRadius};
  box-shadow: ${props => `${props.theme.Base.DropShadowPlacement} rgba(${hexToRgb(props.theme.Base.DropShadowColor)},${props.theme.Base.DropShadowAlpha});`}
  background: ${configService.current().colours.WHITE};
  position: relative;
  z-index: 1;
`;

export const SecondaryWrapper = styled(SummaryWrapper)`
  z-index: 0;
  top: ${props => {
    const {value, unit} = splitValueFromUnit(props.theme.Base.BorderRadius);

    return `-${value * 2}${unit}`;
  }};
  padding-top: ${props => {
    const {value, unit} = splitValueFromUnit(props.theme.Base.BorderRadius);

    return `${value * 2}${unit}`;
  }};
`

export const SummaryContents = styled.div`
  padding: ${props => props.theme.Grid.GutterSize};
`

export const ProfileIcon = styled.img`
  width: 4em;
  height: 4em;
  border-radius: 4em;
`