import React from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { NavTileHeading, NavTileImage, NavTileWrapper, NavBorder } from './NavTile.styles';
import { NavTileProps } from './NavTile.types';

export const NavTile: React.FC<NavTileProps> = ({
  backgroundColour,
  img,
  title,
  linkTo,
  showImage,
}) => {
  const resolved = useResolvedPath(linkTo);
  const match = useMatch({ path: resolved.pathname, end: false });
  
  return (
    <NavTileWrapper backgroundColour={backgroundColour}>
      <Link className='d-block' to={linkTo}>
        <div>
          <NavTileImage img={img} showImage={showImage} />
        </div>
        <NavBorder backgroundColour={backgroundColour} isActive={!!match}>
          <NavTileHeading className='font-heading'>{title}</NavTileHeading>
        </NavBorder>
      </Link>
    </NavTileWrapper>
  )
}
