import { useContext, useRef } from 'react';
import Slider from 'react-slick';
import { BreakpointSetting, CarouselProps, CarouselSettings } from './Carousel.types';
import { CarouselButton, CarouselItem } from './Carousel.styles';
import { ThemeContext } from 'styled-components';


export function Carousel<T>({
  RenderComponent,
  CarouselItems,
  slidesToShow,
  slidesToScroll,
  loop = false,
  animate = false,
  fadeAnimation = false,
  accessibleButtons = false,
  showArrows = false,
  showDotPaging = false,
  initialSlide = 0,
  settingsLG,
  settingsMD,
  settingsSM,
  CarouselItemClasses = '',
}: CarouselProps<T>) {

  const sliderRef = useRef<Slider>(null);
  const { Grid: GridTheme } = useContext(ThemeContext);
  
  const sliderSettings: CarouselSettings = {
    accessibility: accessibleButtons,
    arrows: showArrows,
    autoplay: animate,
    dots: showDotPaging,
    infinite: loop,
    initialSlide,
    slidesToShow,
    slidesToScroll,
    fade: false,
    ref: sliderRef,
    responsive: [] as BreakpointSetting[],
    nextArrow: <CarouselButton />,
    prevArrow: <CarouselButton />
  };

  if (fadeAnimation) {
    sliderSettings.slidesToShow = 1;
    sliderSettings.slidesToScroll = 1;
    sliderSettings.fade = true;
  } else {
    if (settingsLG) {
      sliderSettings.responsive.push({
        breakpoint: GridTheme.Breakpoints.LG,
        settings: settingsLG,
      })
    }

    if (settingsMD) {
      sliderSettings.responsive.push({
        breakpoint: GridTheme.Breakpoints.MD,
        settings: settingsMD,
      })
    }

    if (settingsSM) {
      sliderSettings.responsive.push({
        breakpoint: GridTheme.Breakpoints.SM,
        settings: settingsSM,
      })
    }
  }

  return (
    <div className="pos-relative">
      <Slider {...sliderSettings}>
        {CarouselItems.map((item, index) => (
          <CarouselItem key={index} className={CarouselItemClasses ? CarouselItemClasses : undefined}>
            <RenderComponent {...item} />
          </CarouselItem>
        ))}
      </Slider>
    </div>
  );
}