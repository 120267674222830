import { apiService } from "../../services";
import { UserModel, ApplicationProfileModel } from "outbound-api-client/lib/models";

export const getUser = async (): Promise<UserModel> => {
  if (apiService.instance.isLoggedIn())
  {
    let data = await apiService.instance.getUser();
    return data;
  }
  else
  {
    return new UserModel();
  }
}

export const getApplicationProfile = async (): Promise<ApplicationProfileModel> => {
  if (apiService.instance.isLoggedIn())
  {
    let data = await apiService.instance.getApplicationProfile();
    return data;
  }
  else
  {
    return new ApplicationProfileModel();
  }
}
