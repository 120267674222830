import { CampaignModel } from 'outbound-api-client/lib/models';
import { apiService } from "../../services";
import { AppDispatch } from '../../store';
import { requestCampaigns, requestCampaignsFailure, requestCampaignsSuccess } from '../../store/slices/campaigns.slice';

export const getCampaignsFromAPI = async (campaignIdentifier: string | null = null): Promise<CampaignModel[]> => {
  if (apiService.instance.isLoggedIn())
  {
    let result = await apiService.instance.getCampaigns();
    return result;
  } else {
    return [];
  }
}

export const getCampaigns = async (dispatch: AppDispatch, ) => {
  dispatch(requestCampaigns());

  try
  {
    const campaigns = await getCampaignsFromAPI();
    
    dispatch(requestCampaignsSuccess({
      campaigns,
    }));
  } catch {
    dispatch(requestCampaignsFailure());
  }
}