import React from 'react';
import { Carousel } from '../../../components';
import { FeedItemGroup } from '../Feed.types';
import { HeroItem } from './HeroItem.component';

export const PinnedFeed: React.FC<FeedItemGroup> = ({FeedItems}) => {

  if (FeedItems.length) {
    return (
      <div>
        <Carousel
          RenderComponent={HeroItem}
          CarouselItems={FeedItems}
          slidesToShow={4}
          slidesToScroll={4}
          loop
          animate
          fadeAnimation
          showArrows
          showDotPaging
          CarouselItemClasses='p-bottom-0 d-block'
        />
      </div>
    )
  } else {
    return <></>
  }
}