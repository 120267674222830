import React , {useEffect} from "react";
import { faHome, faPhone, faPhotoFilm, faSignInAlt, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Card } from "..";
import { MenuProps } from "./Menu.types";
import { doLogin, doLogout } from '../../hooks';
import { apiService } from '../../services';
import { getUserStatus } from '../../modules';

import { useAppDispatch, useAppSelector } from '../../store';
import { selectUserStatus } from '../../store/slices/userStatus.slice';


export const Menu: React.FC<MenuProps> = ({
  onMenuItemClick
}) => {


  const userStatus = useAppSelector(selectUserStatus);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!userStatus.isLoaded) {
      getUserStatus(dispatch);
    }
  }, []);


  return (
    <Card cssClasses="edge-corners-top">
      {/* <pre>{JSON.stringify(userStatus, undefined, 5)}</pre> */}
      <div className="d-flex justify-content-start">
        <div className="text-center p-y-2">
          {(!apiService.instance.isLoggedIn()) ?
            <>
              <div className="p-bottom-2">
                <a className="font-subheading color-text text-decoration-none cursor-pointer" onClick={() => doLogin()}>
                  <FontAwesomeIcon size="lg" className="d-block m-x-auto" icon={faSignInAlt} />
                  Login/Register
                </a>
              </div>
              <div className="p-bottom-2">
                <Link to='/' className="font-subheading color-text text-decoration-none" onClick={onMenuItemClick}>
                  <FontAwesomeIcon size="lg" className="d-block m-x-auto" icon={faHome} />
                  Home
                </Link>
              </div>
              {userStatus.user.includes("@OUT-BOUND")?
              <div>
                <Link to='/contact' className="font-subheading color-text text-decoration-none" onClick={onMenuItemClick}>
                  <FontAwesomeIcon size="lg" className="d-block m-x-auto" icon={faPhone} />
                  Contact
                </Link>
              </div>
              :""}
            </>
            : <>
              <div className="p-bottom-2">
                <Link to='/' className="font-subheading color-text text-decoration-none" onClick={onMenuItemClick}>
                  <FontAwesomeIcon size="lg" className="d-block m-x-auto" icon={faHome} />
                  Home
                </Link>
              </div>

              {/* Only show the assets link if the user is a member of outbound */}
              {userStatus && userStatus.user && userStatus.user.toLowerCase().includes("out-bound") && 
              <div className="p-bottom-2">
                <Link to='/assets' className="font-subheading color-text text-decoration-none" onClick={onMenuItemClick}>
                  <FontAwesomeIcon size="lg" className="d-block m-x-auto" icon={faPhotoFilm} />
                  Assets
                </Link>
              </div>}

              <div className="p-bottom-2">
                <Link to='/contact' className="font-subheading color-text text-decoration-none" onClick={onMenuItemClick}>
                  <FontAwesomeIcon size="lg" className="d-block m-x-auto" icon={faPhone} />
                  Contact
                </Link>
              </div>
            </>}

          {(apiService.instance.isLoggedIn()) ?
            <div>
              <a className="font-subheading color-text text-decoration-none cursor-pointer" onClick={doLogout}>
                <FontAwesomeIcon size="lg" className="d-block m-x-auto" icon={faSignOutAlt} />
                Logout
              </a>
            </div>
            : <></>}
        </div>
      </div>
    </Card>
  )
}