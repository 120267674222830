import React from 'react'
import { Row, Cell, Content, Button } from '../../../components';
import { GetTrainingQuestionImage } from '../Training.services';
import { QuizProps } from '../Training.types'
import { Answer } from './Answer.component';

export const QuizQuestions: React.FC<QuizProps> = ({
  questions,
  currentQuestion,
  isAnsweredRight,
  onNextPress,
  onQuestionAnswer,
}) => {
  const question = questions[currentQuestion];
  const isLastQuestion = currentQuestion === questions.length - 1;

  const answerQuestion = (number: number, isCorrect: boolean) => {
    onQuestionAnswer(number, isCorrect);
  }

  const imgUri = GetTrainingQuestionImage(question.contentUris);

  return (
    <div>
      <Row mainAlignment='center'>
        {imgUri.length ? (<Cell baseSize={12} LGSize={4}>
          <img src={imgUri} alt="" style={{width: '100%', borderRadius: 0}} />
        </Cell>) : null}
        <Cell baseSize={12} LGSize={8} className='text-center'>
          <p className='font-heading m-top-05'>{question.question}</p>
          {question.answers.map(a => (
            <div>
              <Answer key={a.number} questionAnswerd={isAnsweredRight} onSelect={() => answerQuestion(a.number, a.isCorrect)} {...a} />
            </div>
          ))}
          {isAnsweredRight && (
            <div className='d-flex flex-column align-items-center'>
              <div className='font-body'><Content template={question.correctAnswerText} /></div>
              <Button hollow onClick={onNextPress}>{!isLastQuestion ? 'Next' : 'Finish'}</Button>
            </div>
          )}
        </Cell>
      </Row>
    </div>
  )
}