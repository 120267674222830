export enum ContentUriType {
  Video = "VIDEO",
  Image = "IMAGE",
  FeedImage = "FEED_IMAGE",
  ThumbnailWide = "THUMBNAIL_WIDE",
  ThumbnailTall = "THUMBNAIL_TALL",
  Document = "DOCUMENT",
  TrainingEpisodeVideo = "TRAINING_EPISODE_VIDEO",
  Stylesheet = "STYLESHEET",
  JavaScript = "JAVASCRIPT",
  Other = "OTHER",
  Badge = "BADGE",
  LiveStream = "LIVE_STREAM",
  WebLink = "WEB_LINK",
  TermsAndConditions = "TERMS_AND_CONDITIONS",
  PrivacyPolicy = "PRIVACY_POLICY",
  YouTubeEmbed = "YOU_TUBE_EMBED",
  VimeoEmbed = "VIMEO_EMBED",
}