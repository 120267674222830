import React from 'react';
import { ImageRadioInputProps } from './ImageRadioInput.types';

export const ImageRadioInput: React.FC<ImageRadioInputProps> = ({
  field,
  form: { touched, errors },
  valuesImage
}) => {

  return (
    <>
      <div className='radio-options'>
        {valuesImage.map((value) => (<>
          <input type="radio" className="input-hidden" id={value.value} {...field} value={value.value} checked={field.value === value.value} />
          <label htmlFor={value.value}>
            <img
              src={value.imageUrl}
              alt={value.value} />
          </label>
        </>))}
      </div>
      {touched[field.name] && errors[field.name] && (<div className="error">{errors[field.name]}</div>)}
    </>
  );
}