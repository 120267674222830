import { Field as FormikField, FieldProps as FormikProps } from 'formik';
import { FieldProps } from './Field.types';

export function Field<T extends FormikProps>({
  name,
  component,
  label,
  validationRule,
  values = [],
  valuesSimple = [],
  valuesImage = [],
  boxPosition = 'left',
  fieldClassNames = '',
}: FieldProps<T>) {

  return (
    <div className={`field ${fieldClassNames}`}>
      {!!label && (<label htmlFor={name}>{label}</label>)}
      <FormikField
        id={name}
        name={name}
        component={component}
        validate={validationRule}
        values={values}
        valuesSimple={valuesSimple}
        valuesImage={valuesImage}
        boxPosition={boxPosition}
      />
    </div>
  )
} 