import styled from "styled-components";

export const LeaderboardContainer = styled.div`

table {

    margin-left: auto;
    margin-right: auto;

    tr {
        background-color: ${props => props.theme.Leaderboard.RowBackgroundColour};
    }
}

`