import React from "react";
import Lottie from 'react-lottie';
import * as animationData from '../../animations/loading_dots.json'
import { LoaderAnimatedContainer } from "./LoaderAnimated.styles";
import { LoaderAnimatedProps } from "./LoaderAnimated.types";

export const LoaderAnimated: React.FC<LoaderAnimatedProps> = ({
  primary = true,
  secondary = false,
}) => {

  let modeClass = '';

  if (primary) modeClass = 'primary';
  if (secondary) modeClass = 'secondary';

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };  

  return (
    <LoaderAnimatedContainer className={modeClass}>
      <Lottie options={defaultOptions}
              height={400}
              width={400} />
    </LoaderAnimatedContainer>
  )
}