import styled from "styled-components";

export const PageContainer = styled.div`
  flex-grow: 1;

  & > div {
    height: 100%;
  }
`

export const PageContainerFixedHeader = styled.div`
  
  flex-grow: 1;

  & > div {
    height: 100%;
  }
`