import React from 'react';
import { FieldProps, getIn } from 'formik';

export const TextInput:React.FC<FieldProps> = ({
  field,
  form: { touched, errors },
}) => {

  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);

  return (
    <>
    <div>
      <input className='font-input' type='text' {...field}  />
    </div>
    {touch && error && (<div className="error">{error}</div>)}
    </>
  );
}

export const TextInputReadOnly:React.FC<FieldProps> = ({
  field,
  form: { touched, errors },
}) => {

  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);

  return (
    <>
    <div>
      <input className='font-input' readOnly={true} type='text' {...field}  />
    </div>
    {touch && error && (<div className="error">{error}</div>)}
    </>
  );
}