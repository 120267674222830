import React, { useEffect } from 'react';
import { AuthenticationProps } from './Authentication.types';
import { useToken } from '../../hooks';
import { useReferrer } from '../../hooks/useReferrer.hook';

export const Authentication: React.FC<AuthenticationProps> = ({
  children
}) => {

  const { getToken } = useToken();
  const { getReferrer } = useReferrer();
       
    useEffect(() => {
    getToken();
    getReferrer();
  }, []);

  return <>{children}</>  
} 