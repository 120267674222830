import { Route } from "./data/types";
import { configService } from './services';
import {
  BidSupport,
  Contact,
  ContentTemplate,
  DealRegistration,
  Enable,
  Assets,
  Episode,
  Home,
  Incentive,
  IncentiveLeaderboard,
  LeaderboardV2,
  Learn,
  Profile,
  Reward,
  RewardInfo,
} from "./pages";
import { Splash } from "./components/Splash";
import { CampaignTemplate } from "./modules";
import { MyDeals } from "./modules/OpportunityManagement/MyDeals.component";
import { SpinTheWheel } from "./components/SpinTheWheel";
import { EditSend, EmailerHome } from "./modules/Emailer";
import { LayoutType } from "./services/config.service.types";

let defaultRoutes = [
  {
    to: '/',
    component: Home,
    name: 'Home',
    requiresAuth: false,
  },
  {
    to: '/learn',
    component: Learn,
    name: 'Learn',
    requiresAuth: true,
  },
  {
    to: '/learn/:episodeId',
    component: Episode,
    name: 'Episode',
    requiresAuth: true,
  },
  {
    to: '/Assets',
    component: Assets,
    name: 'Assets',
    requiresAuth: true,
  },
  {
    to: '/Assets/:filePath',
    component: Assets,
    name: 'Assets',
    requiresAuth: true,
  },
  {
    to: '/enable',
    component: Enable,
    name: 'Enable',
    requiresAuth: true,
  },
  {
    to: '/reward',
    component: Reward,
    name: 'Reward',
    requiresAuth: true,
  },
  {
    to: '/reward/:campaignId',
    component: Incentive,
    name: 'Incentive',
    requiresAuth: true,
  },
  {
    to: '/reward/:campaignId/leaderboard',
    component: IncentiveLeaderboard,
    name: 'Incentive Leaderboard',
    requiresAuth: true,
  },
  {
    to: '/reward/:campaignId/registerdeal',
    component: DealRegistration,
    name: 'Register Deal',
    requiresAuth: true,
  },
  {
    to: '/reward/info',
    component: RewardInfo,
    name: 'Virtual Reward Information',
    requiresAuth: true,
  },
  {
    to: '/bidsupport',
    component: BidSupport,
    name: 'Bid Support',
    requiresAuth: true,
  },
  {
    to: '/profile',
    component: Profile,
    name: 'Profile',
    requiresAuth: true,
  },
  {
    to: '/contact',
    component: Contact,
    name: 'Contact us',
    requiresAuth: true,
  },
  {
    to: '/article/:identifier',
    component: ContentTemplate,
    name: 'Article',
    requiresAuth: false,
  },
  {
    to: '/incentive',
    component: Incentive,
    name: 'Incentive',
    requiresAuth: true,
    nestedRoutes: [
      {
        to: ':campaignId',
        component: CampaignTemplate,
        name: 'CampaignHome',
        requiresAuth: true,
      },
      {
        to: ':campaignId/article/:identifier',
        component: ContentTemplate,
        name: 'Article',
        requiresAuth: true,
      },
      {
        to: ':campaignId/mydeals',
        component: MyDeals,
        name: 'MyDeals',
        requiresAuth: true,
      },
      {
        to: ':campaignId/dealreg',
        component: DealRegistration,
        name: 'DealReg',
        requiresAuth: true,
      },
      {
        to: ':campaignId/dealreg/:opportunityId',
        component: DealRegistration,
        name: 'DealReg',
        requiresAuth: true,
      },
      {
        to: ':campaignId/salestools',
        component: Enable,
        name: 'Enable',
        requiresAuth: true,
      },
      {
        to: ':campaignId/leaderboard',
        component: LeaderboardV2,
        name: 'Leaderboard',
        requiresAuth: true,
      },
      {
        to: ':campaignId/learn',
        component: Learn,
        name: 'Training',
        requiresAuth: true,
      },
      {
        to: ':campaignId/learn/:episodeId',
        component: Episode,
        name: 'Episode',
        requiresAuth: true,
      },
      {
        to: ':campaignId/learn/:episodeId/:rewardId/instantwin',
        component: SpinTheWheel,
        name: 'SpinTheWheel',
        requiresAuth: true,
      }
    ]
  }  
]

let legacyRoutes = [
  {
    to: '/',
    component: Splash,
    name: 'Home',
    requiresAuth: false,
  },
  {
    to: '/incentive',
    component: Incentive,
    name: 'Incentive',
    requiresAuth: true,
    nestedRoutes: [
      {
        to: ':campaignId',
        component: CampaignTemplate,
        name: 'CampaignHome',
        requiresAuth: true,
      },
      {
        to: ':campaignId/article/:identifier',
        component: ContentTemplate,
        name: 'Article',
        requiresAuth: true,
      },
      {
        to: ':campaignId/mydeals',
        component: MyDeals,
        name: 'MyDeals',
        requiresAuth: true,
      },
      {
        to: ':campaignId/dealreg',
        component: DealRegistration,
        name: 'DealReg',
        requiresAuth: true,
      },
      {
        to: ':campaignId/dealreg/:opportunityId',
        component: DealRegistration,
        name: 'DealReg',
        requiresAuth: true,
      },
      {
        to: ':campaignId/salestools',
        component: Enable,
        name: 'Enable',
        requiresAuth: true,
      },
      {
        to: ':campaignId/leaderboard',
        component: LeaderboardV2,
        name: 'Leaderboard',
        requiresAuth: true,
      },
      {
        to: ':campaignId/learn',
        component: Learn,
        name: 'Training',
        requiresAuth: true,
      },
      {
        to: ':campaignId/learn/:episodeId',
        component: Episode,
        name: 'Episode',
        requiresAuth: true,
      },
      {
        to: ':campaignId/learn/:episodeId/:rewardId/instantwin',
        component: SpinTheWheel,
        name: 'SpinTheWheel',
        requiresAuth: true,
      }
    ]
  }  
]

let emailerRoutes = [
  {
    to: '/',
    component: EmailerHome,
    name: 'Home',
    requiresAuth: true,
  },
  {
    to: '/send/:templateId',
    component: EditSend,
    name: 'EditSend',
    requiresAuth: true,
  }
]

function routerSelector(layoutType: LayoutType)
{
  switch (layoutType)
  {
    case LayoutType.Legacy:
      return legacyRoutes;
      break;
    case LayoutType.Emailer:
      return emailerRoutes;
      break;
    case LayoutType.Canon:
    default:
      return defaultRoutes;
      break;
  }
}

export const routes: Route[] = routerSelector(configService.current().layoutType);

