import React from "react";
import { Card } from "..";
import { ImgPlaceholder } from "./CardLoader.styles";

export const CardLoader: React.FC = () => {

  const randomWidth = (Math.floor(Math.random() * (7)) + 4) * 10;

  return (
    <Card cssClasses='m-x-0' contentCSS='p-x-0 p-bottom-05'>
      <ImgPlaceholder />
      <div className="p-x-1">
        <div className="font-heading placeholder"></div>
        <div className="font-body placeholder m-bottom-05"></div>
        <div className="font-body placeholder" style={{width: `${randomWidth}%`}}></div>
      </div>
    </Card>
  );
}