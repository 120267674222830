import React from 'react';
import { FileInputProps } from './FileInput.types';
import { getIn, useFormikContext } from 'formik';

export const FileInput: React.FC<FileInputProps> = ({
  field,
  form: { touched, errors },
  values,
  valuesSimple,
  boxPosition
}) => {

  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);

  const formikProps = useFormikContext();

  const setFieldValue = async (data: any) => {
    formikProps.setFieldValue(field.name, data);
  };

  return (
    <>
      <div>
      <input type="file" {...field} value={undefined}
      onChange={(e) => {
        // Object is possibly null error w/o check
        if (e.currentTarget.files) {
          setFieldValue(e.currentTarget.files[0]);
        }
      }}
       />
      {touch && error && (<div className="error">{error}</div>)}
    </div>
    </>
  );
}