import React from 'react';
import { Thumbnail } from '../../components';
import { Link, useParams } from 'react-router-dom';
import { useCampaignDispatch } from './Campaigns.hooks';
import { useAppDispatch } from '../../store';
import { LayoutType } from '../../services/config.service.types';
import { configService } from '../../services';
import { CampaignSubMenu } from './Campaign.styles';

export const CampaignHeader: React.FC = () => {

  const dispatch = useAppDispatch();
  const { campaignId } = useParams();
  const { campaign, hasContent } = useCampaignDispatch(dispatch, campaignId || '');
  const bannerUris = (!campaign) ? [] : campaign.contentUris.filter(c => /banner/i.test(c.identifier));
  const contentUris = (!campaign) ? [] : campaign.contentUris;
  const title = (!campaign) ? "" : campaign.title;

  const defaultCampaign = hasContent && campaign !== null ? campaign : null;

  return (
    <>
      {(configService.current().layoutType !== LayoutType.Legacy) ?
          <CampaignSubMenu className="wrapper">
            {(defaultCampaign === null) ?
              <Link to={`/`}>Home</Link> : <Link to={`/incentive/${defaultCampaign.identifier}`}>Home</Link>}
            <Link to={`/incentive/${campaignId}/mydeals`}>My Deals</Link>
            <Link to={`/incentive/${campaignId}/dealreg`}>Log Deal</Link>
            {(configService.current().navigationProps.showSalesTools) ?
              <Link to={`/incentive/${campaignId}/salestools`}>Sales Tools</Link> : <></>}
            {(configService.current().navigationProps.showQuiz) ?
              <Link to={`/incentive/${campaignId}/learn`}>Quiz</Link> : <></>}
            {(configService.current().navigationProps.showLeaderboard) ?
              <Link to={`/incentive/${campaignId}/leaderboard`}>Leaderboard</Link> : <></>}
            {(configService.current().navigationProps.showQuiz) ?
              <Link to={`/incentive/${campaignId}/article/Prize_Page_EN_GB`}>Prizes</Link> : <></>}
          </CampaignSubMenu>
         : <></>}
      <div>
        <Thumbnail contentUris={bannerUris.length ? bannerUris : contentUris} altText={title} />
      </div>
    </>
  )
}