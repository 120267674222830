import { Link } from "react-router-dom";
import styled from "styled-components";

export const FeedItemLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  color: ${props => props.theme.TextColour};
  height: 100%;

  & > div {
    margin: 0;
    height: 100%;
  }
`
export const FeedItemWrapper = styled.div`
  padding-bottom: ${props => props.theme.Card.ContentPadding};
`