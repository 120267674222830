import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { ContentUriType } from 'outbound-api-client/lib/models';
import { ThumbnailProps } from './Thumbnail.types';

export const Thumbnail: React.FC<ThumbnailProps> = ({
  contentUris,
  altText,
  breakpoint,
  cssClass = ''
}) => {
  const { Grid } = useContext(ThemeContext);

  const tallbreakpoint = !!breakpoint ? breakpoint : Grid.Breakpoints.SM;

  const mainImgUri = contentUris.find(c => c.type === ContentUriType.ThumbnailWide)?.uri || '';
  const mobileImgUri = contentUris.find(c => c.type === ContentUriType.ThumbnailTall)?.uri || '';
  const defaultImgUri = mainImgUri.length ? mainImgUri : mobileImgUri;

  if (!mainImgUri.length && !mobileImgUri.length) {
    return <></>;
  }

  return (
    <picture className={`w-100 d-block ${cssClass}`}>
      {mobileImgUri.length ? (<source media={`(max-width:${tallbreakpoint}px)`} srcSet={mobileImgUri} />) : undefined}
      <source srcSet={defaultImgUri} />
      <img className={`w-100 d-block ${cssClass}`} src={defaultImgUri} alt={altText??""}  />
    </picture>
  );
}