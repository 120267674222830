import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..';
import { CampaignModel } from 'outbound-api-client/lib/models';

type AllCampaignsFormats = {
  campaigns: Array<CampaignModel>;
}

type CampaignsState = AllCampaignsFormats & {
  isLoading: boolean;
  isLoaded: boolean;
  hasError: boolean;
}

const initialState: CampaignsState = {
  campaigns: [],
  isLoading: false,
  hasError: false,
  isLoaded: false,
};

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    requestCampaigns: (state) => {
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    },
    requestCampaignsSuccess: (state, action: PayloadAction<AllCampaignsFormats>) => {
      return {
        ...state,
        campaigns: [...action.payload.campaigns],
        isLoading: false,
        hasError: false,
        isLoaded: true,
      };
    },
    requestCampaignsFailure: (state) => {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        isLoaded: true,
      }
    },
  }
});

export const { requestCampaigns, requestCampaignsSuccess, requestCampaignsFailure } = campaignsSlice.actions;

export const selectCampaigns = (state: RootState) => state.campaigns.campaigns;

export const selectCampaignByIdentifier = createSelector([
  selectCampaigns,
  (state: RootState, identifier: string) => identifier,
], (campaigns, identifier) => {

  return campaigns.find(e => e.identifier === identifier)
});

export default campaignsSlice.reducer;