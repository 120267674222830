// gotham styles
// bold = 'Gotham-Bold'
// book = 'GothamBook'
// medium = 'Gotham'
// light = 'Gotham-Light'

import { CSSObject } from "styled-components";

export type FontKey = 'Body' | 'SubHeading' | 'Heading' | 'Title' | 'SubTitle' | 'SmallPrint';

export const fonts: Record<FontKey, CSSObject> = {
  Body: {
    fontFamily: "'GothamBook', 'Century Gothic', sans-serif",
    fontSize: '12px',
    marginTop: '0',
    marginBottom: '1em',
    letterSpacing: '-0.4px',
  },
  SubHeading: {
    fontFamily: "'GothamBook', 'Century Gothic', sans-serif",
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '1em',
    letterSpacing: '-0.4px',
  },
  Heading: {
    fontFamily: "'Gotham', 'Century Gothic', sans-serif",
    fontSize: '16px',
    marginTop: '1em',
    marginBottom: '0.25em',
    letterSpacing: '-0.4px',
  },
  Title: {
    fontFamily: "'Gotham', 'Century Gothic', sans-serif",
    fontSize: '20px',
    marginTop: '1em',
    marginBottom: '0.25em',
    letterSpacing: '-0.4px',
  },
  SubTitle: {
    fontFamily: "'Gotham', 'Century Gothic', sans-serif",
    fontSize: '26px',
    marginTop: '0.5em',
    marginBottom: '0.5em',
    letterSpacing: '-0.4px',
  },
  SmallPrint: {
    fontFamily: "'GothamBook', 'Century Gothic', sans-serif",
    fontSize: '10px',
    marginTop: '0',
    marginBottom: '1em',
    letterSpacing: '-0.4px',
  },
}