import React from "react";
import { useNavigate } from "react-router";
import { Button, Card, Thumbnail } from "../../components";
import { CampaignModel } from 'outbound-api-client/lib/models';

export const CampaignTile: React.FC<CampaignModel> = (props) => {
  const navigate = useNavigate();

  const goToCampaign = () => {
    navigate(`/incentive/${props.identifier}`);
  }

  return (
    <Card cssClasses='m-x-0' contentCSS='p-0'>
      <div className='pos-relative'>
        <div>
          <Thumbnail contentUris={props.contentUris} altText={props.title} />
        </div>
        <div style={{
          position: 'absolute',
          bottom: '10px',
          left: '1em',
        }}>
          <Button cssClass='m-0' primary onClick={goToCampaign}>DISCOVER MORE</Button>
        </div>
      </div>
    </Card> 
  )
}