import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import trainingSlice from './slices/training.slice';
import userStatusSlice from './slices/userStatus.slice';
import campaignsSlice from './slices/campaigns.slice';

export const store = configureStore({
  reducer: {
    training: trainingSlice,
    userStatus: userStatusSlice,
    campaigns: campaignsSlice,
  }
});


export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;