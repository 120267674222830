import { SalesIncentiveOpportunityModel } from "outbound-api-client/lib/models";
import { useEffect, useState } from "react";
import { getOpportunities } from "./Opportunity.services";

export const useOpportunities = (campaignIdentifier: string | null, opportunityId: string | null) => {

  const [opportunities, setOpportunities] = useState<Array<SalesIncentiveOpportunityModel>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getOpportunities(campaignIdentifier, opportunityId)
      .then((data) => {
        setOpportunities(data);
      })
      .catch((err) => {
        console.error(err);
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, []);

  return {
    opportunities,
    isLoading,
    hasError,
  }
}
