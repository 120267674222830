import React from "react";
import { useLocation } from "react-router";
import { Row } from "../Row";
import { Cell } from "../Cell";
import { NavTile } from "../NavTile";
import { configService } from '../../services';
import { Nav } from "./MainNav.styles";
import rewardTile from '../../assets/images/tile-reward.jpg';
import learnTile from '../../assets/images/tile-learn.jpg';
import enableTile from '../../assets/images/tile-enable.jpg';

export const MainNav: React.FC = () => {
  const { pathname } = useLocation();
  return (
    <Nav>
      <Row>
        <Cell>
          <NavTile
            backgroundColour={configService.current().colours.LILAC}
            title={"Learn"}
            img={learnTile}
            linkTo={"/learn"}
            showImage={pathname === '/'}
          />
        </Cell>
        <Cell>
          <NavTile
            backgroundColour={configService.current().colours.AQUA}
            title={"Enable"}
            img={enableTile}
            linkTo={"/enable"}
            showImage={pathname === '/'}
          />
        </Cell>
        <Cell>
          <NavTile
            backgroundColour={configService.current().colours.DEEP_ORANGE}
            title={"Reward"}
            img={rewardTile}
            linkTo={"/reward"}
            showImage={pathname === '/'}
          />
        </Cell>
      </Row>
    </Nav>
  );
}