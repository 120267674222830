import React from 'react';
import { TrophyImage } from '.';
import { TrophyWrapper } from './Trophy.styles';
import { TrophyProps } from './Trophy.types';

export const Trophy: React.FC<TrophyProps> = ({
  Identifier,
  Title,
  ImageUri,
  UserAwarded,
  onMouseOver,
  onMouseOut
}) => {

  let imageUri = ImageUri;

  if (!UserAwarded)
  {
    imageUri = `${imageUri.substring(0, imageUri.lastIndexOf("."))}_unachieved.${imageUri.substring(imageUri.lastIndexOf(".")+1)}`;
  }

  return (
    <TrophyWrapper>
      <TrophyImage id={Identifier} src={imageUri} alt={Title} onMouseOver={onMouseOver} onMouseOut={onMouseOut} />
    </TrophyWrapper>
  )
}