export const Colours = {
  PRIMARY: '#CC0000',
  SECONDARY: '#FFF',
  TERTIARY: '#D2D2D2',
  WHITE: '#FFF',
  BLACK: '#000',
  LILAC: '#9F296E',
  AQUA: '#2391C1',
  ORANGE: '#F8AB29',
  GREY_LIGHT: '#E4E4E4',
  GREY_DARK: '#323232',
  RED_LIGHT: "#ED1E26",
  TURQUOISE: "#52E5BA",
  DARK_TEAL: '#22AD85',
  DEEP_AQUA: '#16979A',
  DEEP_BLUE: '#27348B',
  DEEP_ORANGE: '#EC7125',
};
