import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const usePageView = (action: (path: string) => void) => {
  const { pathname } = useLocation();

  useEffect(() => {
    action(pathname);
  }, [pathname, action])
  
}