import { TeamModel } from "outbound-api-client/lib/models";
import { useEffect, useState } from "react";
import { getMyTeam } from "./Contact.services";

export const useMyTeam = (campaignIdentifier = '') => {
  const [myTeam, setMyTeam] = useState<TeamModel | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getMyTeam()
      .then((myTeam) => {
        setMyTeam(myTeam);
      })
      .catch((err) => {
        console.error(err);
        setHasError(true);
      })
      .finally(()=> {
        setIsLoading(false);
      });
  }, []);

  return {
    myTeam,
    isLoading,
    hasError,
  }
}