import { object, string, InferType, date, array, boolean } from 'yup';

export const ProductSelectionSchema = object({
  productCategory: string().required('Required'),
  product: string().required('Required'),
  quantity: string().matches(/^\d+$/, {message: 'Enter a valid number'}).required('Required'),
  demoProductRequired: boolean().notRequired()
});

export const BidSupportFormSchema = object({
  endCustomerName: string().required('You must enter the end customer'),
  expectedSaleDate: date().required('Enter a date in the future'),
  preferredDistributor: string().required('You must enter a valid name'),
  productSelection: array().of(ProductSelectionSchema).min(1, 'Please add at least one product'),
  additionalInfo: string().notRequired()
});

export interface BidSupportFormValues extends InferType<typeof BidSupportFormSchema> {}
export interface ProductSelectionValues extends InferType<typeof ProductSelectionSchema> {}
export type ProductChoice = ProductSelectionValues;
export type ProductChoices = ProductSelectionValues[];