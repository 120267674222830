import React from 'react';
import { ProgressProps } from './Progress.types';

export const Progress: React.FC<ProgressProps> = ({
  max = 100,
  cssClass = '',
  value = 0,
  secondaryStyle = false,
}) => {
  const css = ['progress-bar', cssClass, secondaryStyle ? 'secondary' : ''].filter(Boolean).join(' ');
  return (
    <progress max={max} value={!isNaN(value)?value:0} className={css}>
      <div className={`progress-bar${cssClass.length ? ' ' + cssClass : cssClass}`}>
      <span style={{width: `${!isNaN(value)?value:0}%`}}></span>
      </div>
    </progress>
  )
}