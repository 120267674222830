import React, { useState } from 'react';
import { useAppSelector } from '../../store';
import { selectUserStatus } from '../../store/slices/userStatus.slice';

export const Referral: React.FC = () => {

  const userStatus = useAppSelector(selectUserStatus);
  const [buttonText, setButtonText] = useState<string>("Copy");

  const copyLink = (event: any) => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/?r=${encodeURIComponent(encodeURI(btoa(userStatus.user)))}`);
    setButtonText("Copied!");
  };


  return (
    <>
      {(userStatus.user !== "") && <>
        <div className='card'>
          <h4 className='text-center'>Your Unique Referral Link</h4>
          <p>Become a Referral Champion! Copy your link, send it to your colleagues, and get them signed up to earn extra XP points and unique Trophies.</p>
          <div className="input-group">
            <input type="text" className="form-control referral-link" onClick={copyLink} placeholder="Recipient's username" aria-label="My Referral Code" aria-describedby="basic-addon2" value={`${process.env.REACT_APP_URL}/?referrer=${encodeURIComponent(encodeURI(btoa(userStatus.user)))}`} readOnly={true} />
            <div className="header-link btn-primary" onClick={copyLink}>
              <span className="input-group-text yellow-bg" id="basic-addon2">{buttonText}</span>
            </div>
          </div>
          {/* <br />
          <br />
          <div className='text-center'>
            <img style={{ width: "150px" }} src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMwAAADACAMAAAB/Pny7AAAAElBMVEX///8AAADFxcWMjIxQUFA2NjawYJxVAAAEH0lEQVR4nO2d25LiMAxEIZf//+XdWSyltjU9kk1SA6b1ZnBbOqYqim0l3O6jdmu2ke8364ACs7V9vuIX4yGNKwUjmHJI40rBCKYc0rjyhWEWHJJZd2xODbZnMNWIlgBTVY7DsPl8GuYQCEYwghHMnDDLSixztZhlLtfWz7Lt1hws2NHaLKIoCEoyf2lCL8+fxYCeqAd2jxHnVzCCEYxgBPPPQn5h+QYT1ivCmCsLfkEBM8EIRjCCEczVMKslmLYt5q5o3mnCHQUvABNcMAF6SgWCEYxgBCOYiWBSY6667wDKHuoCwQhm2ENdIBjBDHuoC6aE2avWDdOGPtJyG8gFrR1yYH9I91Grw4wK+kMaVwpGMOWQxpWCEUw5pGXUMLbNaw3I4RN+b20fAAXDIY1butLECU+PAX/RBPNlgrnYBPNlU8BY+wIYu+jTDo+8cFSqrXBxT2FM4B0gz6yYgBJbQz4xZbqs687P5Wp4jGlhHcseBCMYwQhmEhhbVPiQsDYwF7vnlaawEbb/k8UW1jMeA3QMMDBSOPbamAcThl/iKLgg82UdbeuNTfB5dZD4U9b3TFeifEEYftciGMEIRjB/YR5HHocSzkJymEc/ej7TDWOecxg2b+WHddMnmbKY6W/PBvgFmIWNnMHsghGMYATz6TBmeJ7FKgLTpFmGYR52JmD0cV2Dsb0RTBQIRjCCEcx5MOkS4AIYez+PH+XA+3r8C98EbM8h2VC+ZIIX/sStOrBwimQjrtDR6e0DDI3OH85X+Q4gPdVjQjbhbuXQBCMYwQhmEhjPL1DKhqUHtKghDIQwWLPgb5yDkQMMhhaqHgKM2Y4jgYtyMUi5rBEnmG4G4roxN8EIRjCCmQPGruW45gqxZRd5F+AyxRdECBPyCdQohFRXh8pgcN4YTLddUKMpGMEIRjAfAmPVDRQGHwVl7R3aecHF+TBlw58Q23g2d8F+7mkwIbY7jMxgaGyCEYxgBNMBw+qaz7s0nw5TD628cExvGRAGPaRGdwsxJMEIRjCC+RAYWtLWDcM8lGHSeosfaGDPL9QqGK2/dfV7gUOxvzvayCsa4o5m+5xugY5b97PNzGgiZ9uzghGMYAQzKwye8JQNXeGZUTga+j6L/FAFETyghYyUzRc1Nm/Xnxua8doZwQhGMIKZA6acX9AV5pW0CiK0UcDyjL2QAfcpbyHPVOexfAw4vs5GY4IL3nEuGMEIRjCTwsC/FvlDQhbLaTAmcA/ldFve0aTUOGK621oV7NTl0/+mZUbPNAUjGMEI5q1g3vLSzITdAmoorN8BCEYwghGMYOaD8UWZVTn4CKSYoQyBMM3BfqzqroPJYk0tgzG78A5AMIIRjGCeOMY6D4aVHixMEIR4KmWfYzu1UMPQDZNa77yFlWb5qV4cSDBvC/MHjOhk8igNiw8AAAAASUVORK5CYII=' />
          </div> */}
          </div>
      </>
      }
    </>
  )
}