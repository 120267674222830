import React, { useState, useEffect } from 'react';
import { QuizHome } from './QuizHome.component';
import { QuizQuestions } from './QuizQuestions.component';
import { QuizEnd } from './QuizEnd.component';
import { Card, Cell, Progress, Row } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import { TrainingContent, TrainingHeader, TrainingNavButton } from './Training.styles';
import { InteractionEventType, TrainingContentType, TrainingEpisodeModel, TrainingEpisodeQuestionDataModel, TrainingEpisodeResponseDataModel } from 'outbound-api-client/lib/models';
import { postTrainingResults } from '../Training.services';
import { apiService } from '../../../services';
import { useParams } from 'react-router-dom';

export const Quiz: React.FC<TrainingEpisodeModel> = (props) => {
  const [stage, setStage] = useState(0);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [activeQuestionCorrect, setActiveQuestionCorrect] = useState(false);
  const [attempts, setAttempts] = useState<number[]>([]);
  const [quizData, setQuizData] = useState<TrainingEpisodeQuestionDataModel[]>([]);
  const [contentViewed, setContentViewed] = useState(false);
  const [quizResults, setQuizResults] = useState<TrainingEpisodeResponseDataModel>(new TrainingEpisodeResponseDataModel(0, 0));
  const navigate = useNavigate();
  const { campaignId } = useParams();

  useEffect(() => {
    apiService.instance.createInteractionSimple(InteractionEventType.TrainingEpisodeView, props.identifier);

    if (props.contentType === TrainingContentType.Other) {
      setContentViewed(true);
    }
  }, []);

  const goToQuestions = () => {
    setStage(1);
  };

  const goToEnd = async () => {
    setStage(2);
    setActiveQuestion(0);
    setAttempts([]);

    let results = await postTrainingResults(props.identifier, quizData);
    setQuizResults(results);
  };

  const finishQuiz = async () => {
    setContentViewed(false);
    if (!campaignId)
    {
      navigate('/learn');
    }
    else
    {
      navigate(`/incentive/${campaignId}/learn`);
    }
  }

  const setAssetViewed = () => {
    setContentViewed(true);
  }

  const onAnswer = (answerNumber: number, isCorrect: boolean) => {
    const newAttempts = [...attempts, answerNumber];

    setAttempts(newAttempts);

    if (isCorrect) {
      setActiveQuestionCorrect(true);

      const newQuestionResult = new TrainingEpisodeQuestionDataModel(
        activeQuestion + 1,
        answerNumber,
        newAttempts,
        props.questions[activeQuestion].answers.find(a => a.number === answerNumber)?.text ?? '',
        attempts.length + 1
      );

      const questionResults = [...quizData, newQuestionResult];
  
      setQuizData(questionResults);
      setAttempts([]);

      if (!props.questions[activeQuestion].correctAnswerText) {
        nextQuestion();
      }
    }
  }

  const nextQuestion = () => {
    const newQuestion = activeQuestion + 1;

    if (newQuestion === props.questions.length) {
      goToEnd();
    } else {
      setActiveQuestionCorrect(false);
      setActiveQuestion(newQuestion);
    }
  }

  const nextbuttonText = stage === 0 
    ? 'Take the quiz'
    : stage === 2
      ? 'Finish'
      : 'Next';

  const onPrevClick = () => {
    if (stage === 0) {
      navigate(-1);
    } else {
      // do resetting here if we don't want to keep progress of quiz

      setStage(stage - 1);
    }
  }

  const onNext = () => {
    if (stage === 0) goToQuestions();
    if (stage === 1) nextQuestion();
    if (stage === 2) finishQuiz();
  }

  let quizEndProps = {...props, ...quizResults};
  
  return (
    <div>
      {props.title && (<h1 className='text-center'>{props.title}</h1>)}
      <Card>
        <TrainingHeader>
          <Row mainAlignment="space-between">
            <Cell>
              <TrainingNavButton disabled={stage === 2} onClick={onPrevClick}>
                <FontAwesomeIcon icon={faChevronLeft} />
                <span className='font-body m-0'>Back</span>
              </TrainingNavButton>
            </Cell>
            <Cell className="text-right">
              <TrainingNavButton disabled={!contentViewed || (stage === 1 && !activeQuestionCorrect)} onClick={onNext}>
                <span className='font-body m-0'>{nextbuttonText}</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </TrainingNavButton>
            </Cell>
          </Row>
        </TrainingHeader>
        {stage === 1 && (<div>
          <Row mainAlignment='center'>
            <Cell className='text-center'>
              <Progress value={((activeQuestion + 1)/props.questions.length) * 100} />
              <p className='font-body m-top-025'>Question {activeQuestion + 1} of {props.questions.length}</p>
            </Cell>
          </Row>
        </div>)}
        <TrainingContent>
          {stage === 0 && <QuizHome {...props} assetViewed={contentViewed} triggerAssetViewed={setAssetViewed} goToQuestions={goToQuestions} />}
          {stage === 1 && <QuizQuestions {...props} currentQuestion={activeQuestion} onNextPress={nextQuestion} onQuestionAnswer={onAnswer} isAnsweredRight={activeQuestionCorrect} />}
          {stage === 2 && <QuizEnd {...quizEndProps} />}
        </TrainingContent>
      </Card>
    </div>
  )
} 