import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { configService } from '../services';

export const RewardInfo: React.FC = () => {
  const location = useLocation();
  return (
    <div>
      <Helmet>
        <title>{configService.current().applicationTitle} - Reward - Information</title>
      </Helmet>
      {location.pathname}
    </div>
  )
}