import React from 'react';
import { CheckboxInputProps } from './CheckboxInput.types';

export const CheckboxInput: React.FC<CheckboxInputProps> = ({
  field,
  form: { touched, errors },
  values,
  valuesSimple,
  boxPosition,
}) => {

  return (
    <>
      <div className='checkbox-options'>
        { (values.length !== 0) ? values.map((meta) => (
          <div className='checkbox-option field-input' key={meta.identifier}>
            <input id={meta.identifier} type='checkbox' {...field} value={meta.value} />
            <label className={`label-checkbox box-${boxPosition}`} htmlFor={meta.identifier}>{meta.label}</label>
          </div>
        ))
        :
        valuesSimple?.map((meta) => (
          <div className='checkbox-option field-input' key={meta}>
            <input id={`${field.name}_${meta}`} type='checkbox' checked={field.value.filter((val: string) => val === meta).length === 1} {...field} value={meta} />
            <label className={`label-checkbox box-${boxPosition}`} htmlFor={`${field.name}_${meta}`}>{meta}</label>
          </div>
        ))}
      </div>
      {touched[field.name] && errors[field.name] && (<div className="error">{errors[field.name]}</div>)}
    </>
  );
}