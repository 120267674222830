import { AssetModel } from "outbound-api-client/lib/models";
import { useEffect, useState } from "react";
import { LoaderAnimated } from "../../components";
import { EmptyAnimated } from "../../components/EmptyAnimated";
import { configService } from "../../services";
import { buildAssetGroups, getAssets } from "./Assets.services";
import { AssetGroup } from "./Assets.types";
import { AssetCollection } from "./components/AssetCollection.component";

export const useAssets = (
  campaignIdentifier: string | null = null,
  groupByCategory = (configService.current()?.enableProps?.groupByCategory ?? true),
  showTitle = true,
  title = ''
) => {
  const [assets, setAssets] = useState<AssetModel[]>([]);
  const [collections, setCollections] = useState<AssetGroup[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [triedLoading, setTriedLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [query, setQuery] = useState<string | null>(null);

  useEffect(() => {
    setIsLoading(true);
    getAssets(campaignIdentifier, query)
      .then((assets) => {
        setAssets(assets);
        setTriedLoading(true);
      })
      .catch((err) => {
        setHasError(true);
        setIsLoading(false);
      });
  }, [query]);

  useEffect(() => {
    if (assets.length) {
      const groups = buildAssetGroups(assets, groupByCategory, title);
      setHasError(false);
      setCollections(groups);
    } else {
      setCollections([]);
    }
    
    setIsLoading(false);
  }, [assets]);

  const AssetContent = () => {
    return (
      <>
        {
        (!triedLoading || isLoading) ? <LoaderAnimated /> : 
        (triedLoading && collections.length === 0) ? <EmptyAnimated text="No assets found" /> :
        collections.map((group, inx) => (
          <AssetCollection showTitle={showTitle} key={inx} {...group} />
        ))}
      </>
    )
  }



  return {
    isLoading,
    hasError,
    hasContent: !!assets.length,
    AssetContent,
    setQuery
  }
}