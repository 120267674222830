import DOMPurify from "dompurify";
import React from "react";
import { configService } from "../../services";

export const Footer: React.FC = () => {

  const config = configService.current();

  const safesmallPrint = DOMPurify.sanitize(config.footerProps.smallPrint);

  return (
    <div className='d-flex p-x-1 justify-content-between p-y-05'>
      <div>
        <p className='m-0 font-smallprint' dangerouslySetInnerHTML={{__html: safesmallPrint}}></p>
      </div>
      <div>
        <a className='m-0 font-smallprint color-text text-decoration-none' href={config.footerProps.helpUrl}>{config.footerProps.helpText}</a>
      </div>
      {(config.footerProps.cookiePolicyUrl) ?
        <div>
          <a className='m-0 font-smallprint color-text text-decoration-none' href={config.footerProps.cookiePolicyUrl} target="_blank">{config.footerProps.cookiePolicyText}</a>
        </div> : <></>
      }
      {(config.footerProps.termsUrl) ?
        <div>
          <a className='m-0 font-smallprint color-text text-decoration-none' href={config.footerProps.termsUrl} target="_blank">{config.footerProps.termsText}</a>
        </div> : <></>
      }
      <div>
        <a className='m-0 font-smallprint color-text text-decoration-none' href={config.footerProps.privacyUrl} target="_blank">{config.footerProps.privacyText}</a>
      </div>
    </div>
  )
}