import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserStatusModel } from 'outbound-api-client/lib/models'
import { RootState } from '..';

type UserStatusState = UserStatusModel & {
  isLoading: boolean;
  isLoaded: boolean;
  hasError: boolean;
}

const initialState: UserStatusState = {
  user: '',
  displayNameSafe: '',
  teamName: '',
  totalPoints: 0,
  currentLevel: '',
  currentLevelPoints: 0,
  nextLevelIn: 0,
  isLoading: false,
  hasError: false,
  isLoaded: false,
};

const userStatusSlice = createSlice({
  name: 'userStatus',
  initialState,
  reducers: {
    requestUserStatus: (state) => {
      return {
        ...state,
        isLoading: true,
        hasError: false,
      }
    },
    requestUserStatusSuccess: (state, action: PayloadAction<UserStatusModel>) => {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        hasError: false,
        isLoaded: true,
      };
    },
    requestUserStatusFailure: (state) => {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        isLoaded: true,
      }
    },
  }
});

export const { requestUserStatus, requestUserStatusSuccess, requestUserStatusFailure } = userStatusSlice.actions;

export const selectUserStatus = (state: RootState) => state.userStatus;

export default userStatusSlice.reducer;