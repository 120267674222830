import { object, string, InferType, array } from 'yup';

export const UserProfileFormSchema = object({
  firstName: string().required('You must enter a account name'),
  lastName: string().required('You must enter a opportunity value'),
  displayName: string().required('You must enter a display name'),
  companyName: string().required('You must enter a distributor name'),
  industrySpecialism: array().min(1).of(string().required()).required('You must select an industry specialism'),
  interestAreas: array().min(1).of(string().required()).required('You must select an interest specialism')
});

export interface UserProfileFormValues extends InferType<typeof UserProfileFormSchema> {}