import styled from "styled-components";
//import { HeaderMenuProps } from "./LegacyHeader.types";

export const LegacyHeaderBar = styled.header`
position: absolute;
min-width: 100%;

& .mobile-menu {
  display: none;
  z-index: 999;
  position: absolute;
  text-align: center;
  width: 100%;
}

& img {
  max-height: 40px;
  max-width: 100%;
}

& .brand {
  display:inline-flex;
  gap:30px;
}

.wrapper {
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  .block {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
  }

  
  @media only screen and (max-width: 1024px) { 
    display:block;
    .desktop-menu{
      margin-top:10px;
    }
  }

}

& .wrapper>.mobile-menu-toggle, .wrapper>.mobile-menu {
  display: none;
}

.mobile-menu-toggle{
  margin-bottom:15px;
}

& .mobile-menu {
  background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    position:absolute;
    top:55px;

    & a {
      text-decoration: none;
    }

    & a, a:visited, a:active {
      color: #000;
    }

    & a:hover {
      text-decoration: underline;
    }
}

}
`

export const LegacyNav = styled.nav`

display: flex;
justify-content: space-evenly;
width: 100%;

& a{
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  padding-top:5px;
}
`