import { SccGT23Config } from './config.service.scc-gt23';
import { SCCMonteCarloConfig } from './config.service.scc-montecarlo';
import { JabraPromotionClaimConfig } from './config.service.jabrapromotionclaim';
import { AcmeTechConfig } from './config.service.acmetechpep';
import { CanonFocusPepConfig } from './config.service.canonfocuspep';
import { ConfigProps } from './config.service.types';
import { SCCIncentiveConfig } from './config.service.scc-incentive';

class ConfigService {
  private readonly config: ConfigProps;

  constructor() {
    switch (process.env.REACT_APP_CLIENTID) {
      case "scc-gt23":
        this.config = SccGT23Config;
        break;

        case "scc-montecarlo":
        this.config = SCCMonteCarloConfig;
        break;

        case "scc-incentive":
        this.config = SCCIncentiveConfig;
        break;

        case "jabrapromotionclaim":
        this.config = JabraPromotionClaimConfig;
        break;

        case "acmetechpep":
        this.config = AcmeTechConfig;
        break;

      case "canonfocuspep":
      default:
        this.config = CanonFocusPepConfig;
        break;
    }
  }

  current() {
    return this.config;
  }
}

export const configService = new ConfigService();