import React, { ReactNode, useState, useEffect, MouseEventHandler } from 'react';
import { Helmet } from 'react-helmet';
import { Card, Cell, Row, Progress, UserCard, Scrollview, Portal } from '../components';
import { Trophy, TrophyCollection, TrophyDescription } from '../components/Trophy';
import { RewardType } from '../data/enums';
import { useLeaderboard, useUserStatusRefresh } from '../modules';
import { CampaignTile, useCampaigns } from '../modules/Campaigns';
import { useRewards } from '../modules/Reward';
import { configService } from '../services';
import { useAppDispatch, useAppSelector } from '../store';
import { selectUserStatus } from '../store/slices/userStatus.slice';
import noIncentives from '../assets/images/no-incentives.png';

export const Reward: React.FC = () => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipData, setTooltipData] = useState<{
    x: number;
    y: number;
    content?: ReactNode;
  }>({
    x: 0,
    y: 0,
  });

  const dispatch = useAppDispatch();
  const { campaigns } = useCampaigns();
  const { scores } = useLeaderboard();
  const { rewards } = useRewards(RewardType.Badge);
  useUserStatusRefresh(dispatch);

  const userStatus = useAppSelector(selectUserStatus);

  const onMouseOverTrophy: MouseEventHandler<HTMLImageElement> = (e) => {
    if (e.target) {
      const rect = (e.target as HTMLImageElement).getBoundingClientRect();
      const id = (e.target as HTMLImageElement).getAttribute('id');
      const reward = rewards.find(r => r.identifier === id);

      if (reward) {
        setTooltipData({
          x: rect.x + rect.width / 2,
          y: rect.y + rect.height,
          content: (<>
            <h3 className='font-subtitle font-style-regular'>{reward.title}</h3>
            <p className='font-smallprint m-bottom-0'>{reward.description}</p>
          </>)
        });
      }
    }
  }

  const onMouseOutOfTrophy: MouseEventHandler<HTMLImageElement> = (e) => {
    setTooltipOpen(false);
  }

  useEffect(() => {
    setTooltipOpen(true);
  }, [tooltipData])


  var pointsToGo = userStatus?.nextLevelIn - userStatus?.totalPoints;
  var pointsStart = userStatus?.nextLevelIn - userStatus?.currentLevelPoints;
  var pointDifference = pointsStart - pointsToGo;
  var percentage = Math.ceil((pointDifference / pointsStart) * 100);
  

  return (
    <div>
      <Helmet>
        <title>{configService.current().applicationTitle} - Reward</title>
      </Helmet>
      <Card cssClasses='m-x-0 m-top-1 p-bottom-2'>
        <Row>
          <Cell baseSize={12} MDSize={4}>
            <h3 className='font-body text-center m-y-1'>Progress</h3>
            {userStatus && (<div className='text-center'>
              <p className='font-heading font-style-regular m-bottom-0'>{userStatus.currentLevel}</p>
              <div className='p-x-1'>
                {/* <Progress max={userStatus.nextLevelIn} value={userStatus.totalPoints} /> */}
              <div title={(percentage ? percentage : 0) + "%"}>
                <Progress max={100} value={percentage} />
              </div>
              </div>
              <p className='font-smallprint'>Progress: {userStatus.totalPoints}/{userStatus.nextLevelIn}</p>
            </div>)}
          </Cell>
          <Cell baseSize={12} MDSize={4}>
            <h3 className='font-body text-center m-y-1'>Leaderboard</h3>
            <div>
              <Scrollview>
                {scores.map((s, inx) => (
                  <UserCard key={inx} Name={s.Name} CurrentLevel={s.CurrentLevel} Highlight={s.Username === userStatus?.user}  />
                ))}
              </Scrollview>
            </div>
          </Cell>
          <Cell baseSize={12} MDSize={4}>
            <h3 className='font-body text-center m-y-1'>Trophies</h3>
            <div className='pos-relative'>
              <Scrollview>
                <div className='d-flex'>
                  <TrophyCollection>
                    {rewards.map((r, inx) => (
                      <Trophy
                        Identifier={r.identifier}
                        Title={r.title}
                        ImageUri={r.imageUri}
                        UserAwarded={r.userAwarded}
                        key={inx}
                        onMouseOver={onMouseOverTrophy}
                        onMouseOut={onMouseOutOfTrophy}
                      />
                    ))}
                  </TrophyCollection>
                </div>
              </Scrollview>
              {isTooltipOpen && tooltipData.content && tooltipData.x && tooltipData.y ? (
                <Portal>
                  <TrophyDescription x={tooltipData.x} y={tooltipData.y}>
                    {tooltipData.content}
                  </TrophyDescription>
                </Portal>
              ) : null}
            </div>
          </Cell>
        </Row>
      </Card>
      <h2 className='font-title text-center  m-bottom-0'>Incentives</h2>
      <p className='text-center'>Earn real-world rewards by enrolling in available incentives.</p>
      <div>
        { (campaigns.length === 0) ? <p className='text-center'><img src={noIncentives} width="100%" /></p> : 
        campaigns.map(c => (
          <CampaignTile {...c} key={c.identifier} />
        ))}
      </div>
    </div>
  )
}