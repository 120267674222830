export type DealSummaryProps = {
  title: string | null;
  logoUrl: string | null;
  playbookUrl: string;
  oem: string | null;
  borderColor: string;
  mode: DealSummaryMode;
}

export enum DealSummaryMode
{
  MyDeals = 0,
  MyPoints = 1
}