import { useEffect, useState } from "react";
import { useParams, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AssetModel } from "outbound-api-client/lib/models";
import { getAssets } from "../modules/Assets/Assets.services";

import { getUserStatus } from '../modules';
import { useAppDispatch, useAppSelector } from '../store';
import { selectUserStatus } from '../store/slices/userStatus.slice';

import { apiService } from '../services';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCopy, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

export const Assets: React.FC = () => {
    const { filePath } = useParams();

    // const iframeRef = useRef<HTMLInputElement>(null);


    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [assets, setAssets] = useState<AssetModel[]>([]);

    const [filter, setFilter] = useState<string>("");

    const isLoggedIn = apiService.instance.isLoggedIn();


    useEffect(() => {
        setIsLoading(true);
        getAssets("", "")
            .then((assets) => {
                setAssets(assets);
                setIsLoading(false);
                setFilter("")
            })
            .catch((err) => {
                setIsLoading(false);
            });
    }, []);


    const CopyLink = (e: any) => {
        navigator.clipboard.writeText(e.dataset.href);
        toast('The link has been copied to your clipboard', { type: toast.TYPE.SUCCESS, autoClose: 5000 });
    }


    const userStatus = useAppSelector(selectUserStatus);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!userStatus.isLoaded) {
            getUserStatus(dispatch);
        }
    }, []);


    if (isLoading|| !isLoggedIn )
        return <>Loading...</>


    //Open a file inside the page
    if (filePath) {

        const file = assets.find(a => a.name === decodeURI(filePath));

        if (file)

            switch (file.type.toUpperCase()) {
                case ("JPG"):
                case ("GIF"):
                    //case ("TIF"):
                    return <>
                        <img title={file.name} alt={file.name} src={file.uri} />
                        {/* <pre>{JSON.stringify(file, undefined, 5)}</pre> */}
                    </>
                case ("PDF"):
                    return <>

                        <iframe
                            // ref={iframeRef}
                            title={file.name}
                            src={`${file.uri}#view=Fit`}
                            frameBorder={0}
                            style={{ minHeight: "calc(100vh - 200px)" }}
                        />

                    </>
                case ("MP4"):
                    return <>
                        <video controls>
                            <source src={file.uri} type="video/mp4" ></source>
                        </video>
                    </>
                case ("XLS"):
                case ("XLSX"):
                case ("PPTX"):
                    return <>

                        <iframe
                            // ref={iframeRef}
                            title={file.name}
                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${file.uri}`}
                            frameBorder={0}
                            style={{ minHeight: "calc(100vh - 200px)" }}
                        />

                    </>
            }

            //await apiService.postInteraction(InteractionEventType.WebPageView, 'PageView', fi);

        return <>
            {isLoading && <>Loading...</>}
            <pre>
                {JSON.stringify(file, undefined, 5)}
            </pre>
        </>
    }


    //Redirect to home page if the user isn't part of outbound - they are not allowed to see the list
    if (!userStatus.user.includes("@OUT-BOUND"))
        return <Navigate to={"/"} />;



    //Product sorting and filtering
    let filteredAssets = assets;
    filteredAssets = filteredAssets.filter(a => a.name.includes(filter));
    filteredAssets = filteredAssets.sort((a, b) => a.name > b.name ? 1 : -1);



    //Group by product family
    const productFamily = Array.from(new Set(filteredAssets.map(item => item.productFamily)));
    productFamily.sort();



    //Group by product family
    const fileTypes = Array.from(new Set(filteredAssets.map(item => item.type)));
    fileTypes.sort();



    const openLinkFileTypes = ["XLS", "XLSX", "PPTX", "PDF", "GIF", "JPG", "MP4"];




    return <>
        <h3 className="font-title text-center">Assets</h3>
        {
            !isLoading && <>
                {
                    productFamily.map((pf, ipf) =>
                        <div key={`productFamily_${ipf}`}>
                            <h4 className="m-y-0 m-top-1 m-bottom-1">{pf}</h4>
                            <div className="row">
                                {filteredAssets.filter(a => a.productFamily === pf).map((a, i) => {
                                    const fileUrl = encodeURI(`${process.env.REACT_APP_URL}/assets/${a.name}`);
                                    return <div key={`asset_${i}`} className="col">
                                        <div className="card d-flex gap-2 align-items-start m-bottom-0">
                                            <img alt={a.type} src={`images/icons/${a.type}.png`} style={{ maxWidth: "50px", minWidth: "50px", width: "50px" }} />
                                            {/* <img alt={a.type} src={a.thumbnail} style={{ maxWidth: "100px", minWidth: "100px", width: "100px" }} /> */}
                                            <div className="d-flex flex-column gap-2 w-100">
                                                <div className="flex-grow-1">{a.name}</div>
                                                <div className="d-flex justify-content-left gap-2">

                                                    {openLinkFileTypes.includes(a.type.toUpperCase()) &&
                                                        <a target="_blank" rel="noreferrer" title="Open Link" href={fileUrl} className="btn btn-link">
                                                            Open Link
                                                            {/* <FontAwesomeIcon style={{ color: "#000" }} className="d-block m-x-auto" icon={faArrowUpRightFromSquare} /> */}
                                                        </a>}

                                                    <button type="button" title="Copy Link" onClick={(e) => CopyLink(e.target)} className="btn btn-link" data-href={fileUrl}>
                                                        Copy Link
                                                        {/* <FontAwesomeIcon style={{ color: "#000" }} className="d-block m-x-auto" icon={faCopy} /> */}
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                )}
                            </div>
                        </div>
                    )}
                {filePath}

                {/* <small>
                    <pre>{JSON.stringify(filteredAssets, undefined, 5)}</pre>
                </small> */}
            </>
        }
    </>


}